import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import axios from "../../../../../axios-instance/backendAPI";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "block",
    boxSizing: "border-box",
    width: "250px",
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "2px",
    fontSize: "14px",
  },
}));

export default function Delete(props) {
  const { register, handleSubmit, errors, setError } = useForm();
  const classes = useStyles();
  const token = useSelector(state => state.auth.token)

  const onSubmit = (data) => {
    const payload = {
      username: props.username,
      password: data.password
    }
    axios
      .post("/user/delete", payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.message === "Invalid Token...") {
          return;
        }

        if (response.data.success) {
          alertify.success(response.data.message);
          props.updateRows();
          props.handleClose();
        } else {
          setError("password", {
            type: "manual",
            message: (
              <span style={{ color: "red" }}>{response.data.message}</span>
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <h3>Are u sure u want to delete {props.username}?</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          id="password"
          name="password"
          type="password"
          ref={register({
            required: (
              <span style={{ color: "red" }}>You must specify a password</span>
            ),
          })}
          className={classes.input}
          autoFocus
        />
        <br />
        {errors.password && <p>{errors.password.message}</p>}
        <Button type="submit" variant="contained">
          Submit
        </Button>
        <p>Note: All downlinks along with their records will be deleted too!</p>
      </form>
    </>
  );
}
