import React,{useState, useEffect} from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Event from "./Event/Event";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Inplay from "../Inplay/Inplay";
import axios from '../../../../axios-instance/backendAPI';
import casino_axios from '../../../../axios-instance/casinoAPI';
import { useSelector } from "react-redux";
import Carousel from 'react-material-ui-carousel';
// import image1 from '../../../../assets/images/image1.jpeg'
import image2 from '../../../../assets/images/image2.jpeg'
import image3 from '../../../../assets/images/image3.jpeg'
import image4 from '../../../../assets/images/image4.jpeg'
import { CasinoURLS, Games } from "../../../../helpers/constant";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

const useStyles = makeStyles((theme) => ({
  tabs: {
    background: 'var(--secondary)',
    color: 'var(--header_color)',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      background: 'var(--secondary)',
      color: 'var(--header_color)',
    }
  },
  tabWrapper: {
    flexDirection: 'row',
    textTransform: 'capitalize',
    fontSize: 'var(--large_font)',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)'
    }
  },
  tabIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.4),
    fontSize: 'var(--very_large_font)',
  },
  tabLabel: {
    display: 'inline-block',
  },
  active: {
    fontWeight: 700,
    backgroundColor: 'var(--heading)',
    color: '#fff'
  },
  img: {
    height: '250px',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      height: '190px',
    }
  },
  games:{
    margin: '20px 0 0 5px'
  },
  gamesHeading: {
    fontSize: 'var(--very_large_font)',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--medium_font)',
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [matches, setMatches] = useState();
  const [games, setGames] = useState([])
  const token = useSelector(state => state.auth.token)
  const usertype = useSelector(state => state.auth.usertype)

  const items = [
    // { name: 'Image 1', src: image1, redirectURL: '' },
    { name: Games.ROULETTE, src: image2, redirectURL: CasinoURLS.ROULETTE },
    { name: 'Image 3', src: image3, redirectURL: '' },
    { name: 'Image 4', src: image4, redirectURL: '' },
  ];

  const isCasinoEnabled = async ()  => {
    const response = await axios.get('/user/getCasinoStatus', { headers: { Authorization: `Bearer ${token}` } })
    if(response.data.success){
      return response.data?.data?.[0].status === 'on' ? true : false
    }
    return false
  }

  const getAllGames = async () => {
    const response = await casino_axios.get('/admin/getAllGames')
    if (response.data.success)
      setGames(response.data.data)
}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const redirectToGame = async (game_name) => {
    const isEnabled = await isCasinoEnabled()
    if(!isEnabled) return alertify.error('Casino Disabled! Contact Upline!')
    window.location.href = CasinoURLS[game_name]
  }

  const redirectFromImage = (url) => {
    url && (window.location.href = url)
  }

  useEffect(() => {
    getAllGames()
    axios.get('/user/getAllMatchesWithOdds', { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
        if (response.data.success && Array.isArray(response.data.data))
          setMatches(response.data.data)
    })
    .catch(error => {
        setMatches([])
        console.log(error);
    })
  }, [])

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          // indicatorColor="none"
          onChange={handleChange}
          className={classes.tabs}
          variant="fullWidth"
          aria-label="simple tabs example"
        >
          <Tab className={value === 0 ? classes.active : ''} classes={{ wrapper: classes.tabWrapper }} label="Inplay" icon={<LiveTvIcon className={classes.tabIcon}/>} {...a11yProps(0)}/>
          <Tab className={value === 1 ? classes.active : ''} classes={{ wrapper: classes.tabWrapper }} label="Cricket" icon={<SportsCricketIcon className={classes.tabIcon}/>} {...a11yProps(1)} />
          <Tab className={value === 2 ? classes.active : ''} classes={{ wrapper: classes.tabWrapper }} label="Soccer" icon={<SportsSoccerIcon className={classes.tabIcon}/>} {...a11yProps(2)} />
          <Tab className={value === 3 ? classes.active : ''} classes={{ wrapper: classes.tabWrapper }} label="Tennis" icon={<SportsTennisIcon className={classes.tabIcon}/>} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Carousel stopAutoPlayOnHover indicators={false} interval={5000} animation="slide">
        {items.map((item, index) => (
          <Paper key={index}>
            <img src={item.src} alt={item.name} className={classes.img} onClick={()=>redirectFromImage(item.redirectURL)} />
          </Paper>
        ))}
      </Carousel>
      <TabPanel value={value} index={0}>
        <Inplay matches={matches}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Event id="4" index={0} activeIndex={value} matches={matches} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Event id="1" index={1} activeIndex={value} matches={matches} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Event id="2" index={2} activeIndex={value} matches={matches} />
      </TabPanel>
      {usertype==='5' && <div className={classes.games}>
        {games.reduce((toshow, game)=>{
          return toshow || game.enabled
        }, false) && <h2 className={classes.gamesHeading}>Games</h2>}
        {games.map(game=>{
          if(game.enabled)
            return <img 
              src={items.filter(item=>item.name.toLowerCase()===game.name)[0].src} 
              style={{cursor: 'pointer'}} 
              width= "200px" 
              onClick={() => redirectToGame(game.name.toUpperCase())}
            />
          return <></>
        })}
        </div>}
    </div>
  );
}
