import React, { useEffect, useState } from 'react'
import axios from '../../../../axios-instance/backendAPI'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@material-ui/core";
import { useSelector } from 'react-redux';
import { formatCoins, redirectToFullMarket } from '../../../../helpers/common';
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    table: {
        overflow: "scroll",
        '& .MuiTableCell-root': {
            fontSize: 'var(--large_font)', // Adjust the font size as needed
        },
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
            '& .MuiTableCell-root': {
                fontSize: 'var(--small_font)', // Adjust the font size as needed
            },
        },
    },
}))

export default function RunningMarketAnalysis() {

    const classes = useStyles();
    const [markets, setMarkets] = useState([])
    const token = useSelector(state => state.auth.token)

    useEffect(() => {

        axios.get('/user/runningMarketAnalysis', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    setMarkets(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    let analysis = null

    if (markets.length) {

        analysis = markets.map(market => {
            return <TableRow key={market.market_id}>
                <TableCell align='center'>{market.market_id}</TableCell>
                <TableCell
                    align='center'
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={()=>redirectToFullMarket(market.event_id)}>{market.event}</TableCell>
                <TableCell align='center'>{market.market}</TableCell>
                <TableCell align='center'>{market.sport}</TableCell>
                <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                    {market.runners.map(runner => {
                        return <div key={runner.selectionId}>
                            <p>{runner.name}(<span style={{ fontWeight: '700', color: runner.net_profit >= 0 ? 'green' : 'red' }}>{formatCoins(runner.net_profit)}</span>)</p>
                        </div>
                    })}
                </TableCell>
            </TableRow>
        })
    } else {
        analysis = <TableRow><TableCell>No data</TableCell></TableRow>
    }

    return (
        <div><TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ fontWeight: 700 }}>Market_Id</TableCell>
                        <TableCell align='center' style={{ minWidth: '200px', fontWeight: '700' }}>Event</TableCell>
                        <TableCell align='center' style={{ minWidth: '90px', fontWeight: '700' }}>Market</TableCell>
                        <TableCell align='center' style={{ fontWeight: 700 }}>Sport</TableCell>
                        <TableCell align='center' style={{ minWidth: '450px', fontWeight: '700' }}>Current_Position</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {analysis}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    )
}
