import axios from "axios";

export const localStorageGetItem = (key) => {
    let result = '';
    try {
      if (typeof window === 'object' && window.localStorage) {
        result = window.localStorage.getItem(key);
      }
      return result;
    } catch (error) {
      result = '';
    }
    return result;
  };

export const dateFormatter = (date) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }

  return new Date(date).toLocaleDateString(undefined, options)
}

export const parseDateString = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date)) {
      return { error: "Invalid date string" };
  }

  const options = { month: 'short', day: 'numeric' };
  const datePart = date.toLocaleDateString('en-IN', options);
  const timePart = date.toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });

  return {
      date: datePart,
      time: timePart
  };
}

export function getDeviceType() {
  const ua = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
  }
  return "desktop";
}

export function redirectToFullMarket(event_id){
  window.location.href = `/fullmarket/${event_id}`
}

export function reloadPage () {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export function formatCoins (str, maximumFractionDigits=2) {
  const locale = 'en-IN';
  return str?.toLocaleString(locale,{
    maximumFractionDigits: maximumFractionDigits
  })
}

export function transformToBookmakerOdds (odds) {
  return (parseFloat(odds) * 1000 - 1000)/10
}

export async function getSportsRadarId (eventName) {

  const response = await axios.get(`https://www.nice77777.com/global/get-events-for-sport`)
  const sport_radar_id = response.data.events.filter(event=>event.event_name===eventName)?.[0]?.sport_radar_id
  if(!sport_radar_id) return ''

  return sport_radar_id
}