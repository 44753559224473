import React from 'react';
import { Table, TableRow, TableCell, TableBody, Paper, TableContainer, TableHead } from "@material-ui/core";
import EventRow from '../Dashboard/Event/EventRow';
import { makeStyles } from "@material-ui/core/styles";
import BetSpinner from '../../../UI/Spinner/BetSpinner';

const useStyles = makeStyles((theme) => ({
    
    row: {
        display: 'grid',
        gridTemplateColumns: '46% 18% 18% 18%',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '20% auto auto auto'
        }
    },
    head: {
        textAlign: 'center',
        padding: '5px',
        fontSize: 'var(--medium_font)',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 'var(--small_font)',
        }
    },
    cell: {
        textAlign: 'left',
        padding: '10px 4px 20px'
    }
}))

const Inplay = (props) => {
    const classes = useStyles()
    const matches = props?.matches?.filter(match=>match?.odds?.[0]?.inplay)

    let allMatches = <TableRow><TableCell colSpan={4}><BetSpinner /></TableCell></TableRow>
    let cricket = null
    let soccer = null
    let tennis = null

    if (matches) {

        if (matches.length) {

            cricket = matches.filter(match => match.sport === '4').map(match => {

                return <EventRow
                    key={match.marketId}
                    marketId={match.marketId}
                    marketStartTime={match.marketStartTime}
                    name={match.matchName}
                    matchId={match.matchId}
                    sport={props.id}
                    cupRate={match.cupRate}
                    odds={match.odds && match.odds.length ? match.odds[0] : ''}
                    inplay={true}
                    noOfRunners={match.odds && match.odds.length ? (match.odds[0].runners ? match.odds[0].runners.length : 0) : 0} />
            })

            soccer = matches.filter(match => match.sport === '1').map(match => {

                return <EventRow
                    key={match.marketId}
                    marketId={match.marketId}
                    marketStartTime={match.marketStartTime}
                    name={match.matchName}
                    matchId={match.matchId}
                    sport={props.id}
                    cupRate={match.cupRate}
                    odds={match.odds && match.odds.length ? match.odds[0] : ''}
                    inplay={true}
                    noOfRunners={match.odds && match.odds.length ? (match.odds[0].runners ? match.odds[0].runners.length : 0) : 0} />
            })

            tennis = matches.filter(match => match.sport === '2').map(match => {

                return <EventRow
                    key={match.marketId}
                    marketId={match.marketId}
                    marketStartTime={match.marketStartTime}
                    name={match.matchName}
                    matchId={match.matchId}
                    sport={props.id}
                    cupRate={match.cupRate}
                    odds={match.odds && match.odds.length ? match.odds[0] : ''}
                    inplay={true}
                    noOfRunners={match.odds && match.odds.length ? (match.odds[0].runners ? match.odds[0].runners.length : 0) : 0} />
            })

            allMatches = <>
                {Array.isArray(cricket) && cricket.length ? <>
                    <TableRow>
                        <TableCell className={classes.cell}>{`Cricket(${cricket.length})`}</TableCell>
                    </TableRow>
                {cricket}
                </> : null}
                {Array.isArray(soccer) && soccer.length ? <>
                    <TableRow>
                        <TableCell className={classes.cell}>{`Soccer(${soccer.length})`}</TableCell>
                    </TableRow>
                    {soccer}
                </> : null}
                {Array.isArray(tennis) && tennis.length ? <>
                    <TableRow>
                        <TableCell className={classes.cell}>{`Tennis(${tennis.length})`}</TableCell>
                    </TableRow>
                    {tennis}
                </> : null}
            </>
        } else {
            allMatches = <TableRow><TableCell align='center' colSpan={4}>No Inplay Matches found</TableCell></TableRow>
        }
    }

    return <TableContainer component={Paper}>
        <Table aria-label="simple table" >
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell className={classes.head}></TableCell>
                    <TableCell className={classes.head}>1</TableCell>
                    <TableCell className={classes.head}>X</TableCell>
                    <TableCell className={classes.head}>2</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allMatches}
            </TableBody>
        </Table>
    </TableContainer>
}

export default Inplay