import React from "react";
import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import axios from "../../../../../axios-instance/backendAPI";
import { Formik } from "formik";
import * as yup from "yup";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useSelector } from "react-redux";

const Credit = (props) => {
  const token = useSelector(state => state.auth.token)

  const schema = yup.object({
    chips: yup
      .number()
      .required(<p style={{ color: "red" }}>Credit required</p>)
      .min(
        0,
        <p style={{ color: "red" }}>Credit must be greater than 0</p>
      )
      .max(
        1000000000,
        <p style={{ color: "red" }}>Credit must be smaller than 1000000000</p>
      ),
    password: yup
      .string()
      .required(<p style={{ color: "red" }}>Password required</p>),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data, { setErrors, resetForm, setSubmitting }) => {
        setSubmitting(true);

        const payload = {
          username: props.username,
          credit_ref: data.chips,
          password: data.password
        };

        axios
          .patch("/user/creditRef", payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.success) {
              alertify.success(response.data.message);
              props.updateRows();
              resetForm({
                chips: "",
                passsword: "",
              });
              props.handleClose();
            } else {
              setErrors({
                password: (
                  <span style={{ color: "red" }}>{response.data.message}</span>
                ),
              });
            }
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
          });
      }}
      initialValues={{
        chips: "",
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <h3>Credit to {props.username}</h3>
          <hr />
          <Grid
            className={props.gridy}
            container
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <TextField
                label="Free chips"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.chips}
                name="chips"
                type="number"
                autoFocus
              />
              {touched.chips && errors.chips}
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.password}
                name="password"
                type="password"
              />
              {touched.password && errors.password}
            </Grid>
          </Grid>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            autoFocus
            style={{marginTop: '5px'}}
          >
            Credit
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Credit;