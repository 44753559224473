import React, { useEffect, useState } from 'react'
import { Table, TableRow, TableCell, TableHead, TableBody, Paper, TableContainer, Switch } from "@material-ui/core";
import axios from '../../../../axios-instance/backendAPI'
import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css'
import { useSelector } from 'react-redux';

export default function BlockMarket() {

    const [sports, setSports] = useState([])
    const [flag, setFlag] = useState(false)
    const token = useSelector(state => state.auth.token)

    const handleChange = (event_type) => {
        axios.get('/user/toggleSport/' + event_type, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    setFlag(prevState=>{
                        return !prevState
                    })
                }else{
                    alertify.error(response.data.message)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {

        axios.get('/user/sportList', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    setSports(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, [flag])

    let sportRow = null

    if (sports.length) {

        sportRow = sports.map(sport => {
            return <TableRow key={sport.event_type}>
                <TableCell>{sport.event_type}</TableCell>
                <TableCell>{sport.name}</TableCell>
                <TableCell><Switch
                    checked={sport.status === 'on' ? true : false}
                    onChange={() => { handleChange(sport.event_type) }}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                /></TableCell>
            </TableRow>
        })

    } else {

        sportRow = <TableRow><TableCell>No data</TableCell></TableRow>
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sportRow}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
