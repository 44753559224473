import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

export default function TransitionsModal(props) {

    const { children, open, onClose, bigmodal } = props

    const useStyles = makeStyles((theme) => ({
        modal: {
            width: bigmodal ? "100%" : "50%",
            margin: '0 auto',
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 700,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
}