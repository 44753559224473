import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector} from "react-redux";
import UserList from "./RightSection/UserList/UserList";
import ChildList from "./RightSection/ChildList/Childlist"
import Dashboard from "./RightSection/Dashboard/Dashboard";
import ChangePassword from './RightSection/ChangePassword/ChangePassword';
import AccountStatement from "./RightSection/UserList/Actions/AccountStatement";
import RunningMarketAnalysis from "./RightSection/RunningMarketAnalysis/RunningMarketAnalysis";
import FullMarket from './RightSection/Fullmarket/Fullmarket';
import BetHistory from './RightSection/BetHistory/BetHistory';
import ExposureBets from './RightSection/ExposureBets/ExposureBets';
import BlockMarket from "./RightSection/BlockMarket/BlockMarket";
import ProfitLoss from "./RightSection/ProfitLoss/ProfitLoss";
import UserProfitLoss from "./RightSection/UserList/Actions/ProfitLoss";
import ClientPL from "./RightSection/ClientPL/ClientPl";
import MarketReport from "./RightSection/MarketReport/MarketReport";
import UserPL from "./RightSection/UserPL/UserPL";
import Activity from "./RightSection/Activity/Activity";
import FancyStakes from "./RightSection/FancyStakes/FancyStakes";
import ChipSummary from "./RightSection/ChipSummary/ChipSummary";
import UserActivity from "./RightSection/UserList/Actions/UserActivity";
import AttachBetSlip from "../Hoc/AttachBetSlip";
import Sport from "./RightSection/Sport";

export default function User() {
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath)

  return <Switch>
    <Route path="/dashboard"><AttachBetSlip><Dashboard /></AttachBetSlip></Route>
    <Route path="/sport/:id"><AttachBetSlip><Sport /></AttachBetSlip></Route>
    <Route path="/fullmarket/:matchId" component={FullMarket} />
    <Route path="/blockMarket" component={BlockMarket} />
    <Route path="/runningMarketAnalysis" component={RunningMarketAnalysis} />
    <Route path="/fancyStakes" component={FancyStakes} />
    <Route path="/clientPL" component={ClientPL} />
    <Route path="/userPL" component={UserPL} />
    <Route path="/activity" component={Activity} />
    <Route path="/profitLoss" component={ProfitLoss} />
    <Route path="/marketReport" component={MarketReport} />
    <Route path="/chipSummary" component={ChipSummary} />
    <Route path="/bethistory" component={BetHistory} />
    <Route path="/userProfitLoss/:username" component={UserProfitLoss} />
    <Route path="/userlist/:usertype" component={UserList} />
    <Route path="/childlist/:username" component={ChildList} />
    <Route path="/expoBets/:username" component={ExposureBets} />
    <Route path="/changePassword" component={ChangePassword} />
    <Route path="/accountStatement" component={AccountStatement} />
    <Route path="/userActivity/:username" component={UserActivity} />
    <Redirect to={authRedirectPath} />
  </Switch>
}