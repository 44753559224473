import React, { useEffect, useRef, useState } from 'react'
import classes from '../MarketTable.module.css'

export default function OddsBlock(props) {

    const ref = useRef(false)
    const [highlight, setHighlight] = useState(false)
    const back = props.availableToBack && props.availableToBack[props.availableToBack.length - props.num + props.shift]
    const backPrice = back && back.price ? back.price : null
    const backSize = back && back.size ? back.size : null

    useEffect(() => {

        if (ref.current) {
            setHighlight(true)
            setTimeout(() => {
                setHighlight(false)
            }, 300);
        }
        ref.current = true
    }, [backPrice, backSize])

    return (
        <div className={[classes.element, highlight ? classes.highlight : ''].join(' ')}>
            <div>{backPrice ? backPrice : 0}</div>
            <div className={props.tiny}> {backSize && backSize!=='0' ? (backSize > 1000 ? (backSize / 1000).toFixed(2) + 'k' : backSize) : null}</div>
        </div>
    )
}