import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  InputBase,
  Switch,
  Button
} from "@material-ui/core";
import Modal from "../../../UI/Modal/Modal";
import SearchIcon from "@material-ui/icons/Search";
import HelperLabel from "./HelperLabel/HelperLabel";
import axios from "../../../../axios-instance/backendAPI";
import AddUser from "./Actions/AddUser";
import Deposit from "./Actions/Deposit";
import Withdraw from "./Actions/Withdraw";
import ViewInfo from "./Actions/ViewInfo/ViewInfo";
import ChangePassword from "./Actions/ChangePassword/ChangePassword";
import Delete from "./Actions/Delete";
import Settlement from './Actions/Settlement'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import ChangeName from './Actions/ChangeName'
import Credit from "./Actions/Credit";
import Partnership from "./Actions/Partnership";
import { useSelector } from "react-redux";
import BetSpinner from "../../../UI/Spinner/BetSpinner";
import { formatCoins } from "../../../../helpers/common";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const params = useParams();
  let usertype = params.usertype;

  let headCells = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Username",
    },
    {
      id: "uplink",
      numeric: false,
      disablePadding: false,
      label: "Uplink",
    },
    {
      id: "credit_limit",
      numeric: true,
      disablePadding: false,
      label: "Credit_Limit",
    },
    {
      id: "balance",
      numeric: true,
      disablePadding: false,
      label: "Balance",
    },
    {
      id: "exposure",
      numeric: true,
      disablePadding: false,
      label: usertype === "5" ? "Exposure" : "Credit_Given",
    },
    {
      id: "winnings",
      numeric: true,
      disablePadding: false,
      label: "Winnings",
    },
    {
      id: "partnership",
      numeric: true,
      disablePadding: false,
      label: "Partnership",
    },
    {
      id: "credit_ref",
      numeric: true,
      disablePadding: false,
      label: "Credit_Ref",
    },
    {
      id: "suspended",
      numeric: true,
      disablePadding: false,
      label: "Suspended",
    },
    {
      id: "bet_suspended",
      numeric: true,
      disablePadding: false,
      label: "Bet_Suspended",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
    {
      id: "se",
      numeric: true,
      disablePadding: false,
      label: "SE",
    },
  ];
  const { classes, order, orderBy, onRequestSort, uplink } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  if (uplink >= 2) {
    headCells[1].label = users[uplink - 2].type;
  }

  if (uplink === 1) {
    delete headCells[1];
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.heading} padding="normal">
          S.No
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.heading}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingcenter: theme.spacing(1),
    background: "var(--secondary)",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  paper: {
    position: "absolute",
    maxWidth: 450,
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: " 0 25px 10px",
  },
  bigpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    height: "500px",
    overflowY: "scroll",
    position: "relative",
  },
  title: {
    marginRight: "10px",
    color: '#fff',
    fontSize: 'var(--large_font)',
    textTransform: 'capitalize',
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)',
    },
  },
  addUserBtn: {
    fontSize: 'var(--large_font)',
    padding: '2px 5px',
    textTransform: 'capitalize',
    backgroundColor: '#4e4b4b',
    fontWeight: '600',
    color: '#fff',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)',
    },
  },
  modal: {
    marginLeft: "430px",
    marginTop: "115px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  mod: {
    margin: "0px 80px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  gridy: {
    marginBottom: "5px",
  },
}));

const users = [
  { type: "Seniorsuper", usertype: "2" },
  { type: "Supermaster", usertype: "3" },
  { type: "Master", usertype: "4" },
  { type: "Client", usertype: "5" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '600%',
    [theme.breakpoints.down("sm")]: {
      width: '200%'
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    padding: "2px",
    fontWeight: "bold",
    fontSize: "0.8em",
    backgroundColor: "#e7e7e7",
    border: "1px solid #8a8a82",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid black",
    float: "right",
    marginBottom: '10px',
    width: "18%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3),
      width: "30%",
      height: "30px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      '& svg': {
          fontSize: 'var(--very_large_font)', // Font size for input
      },
  }
  },
  actionButton: {
    margin: 3,
    cursor: "pointer",
    backgroundColor: '#4e4b4b',
    color: '#fff',
    height: '23px',
    width: '40px',
    fontSize: '13px',
    fontWeight: 700,
    textAlign: 'center'
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 'var(--large_font)',
    [theme.breakpoints.up("md")]: {
        width: "20ch",
    },
    [theme.breakpoints.down("sm")]: {
        paddingLeft: `calc(${theme.spacing(4)}px)`,
        fontSize: 'var(--small_font)',
    },
  },
}));

export default function UserList() {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("website");
  const [page, setPage] = useState(0);
  const [rows, setrows] = useState(null);
  const [bufferRows, setBufferRows] = useState([])
  const [search, setSearch] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [show, setShowAdd] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    balance: "",
    usertype: "",
    coins_generated: "",
  });
  const [uplink, setUplink] = useState(0);
  const [shouldUpdate, setUpdate] = useState(false);
  const [action, setAction] = useState({ toRender: "", username: "" });
  const [open, setOpen] = React.useState(false);
  const [bigmodal, setBigmodal] = useState(false);
  const token = useSelector(state => state.auth.token)
  const myUsertype = useSelector(state => state.auth.usertype)
  const [commission, setCommission] = useState()

  const handleOpen = () => {
    setOpen(true);
    setAction();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const params = useParams();
  const history = useHistory();

  const columns = [
    {
      id: "username",
      align: "center",
    },
    {
      id: "uplink",
      minwidth: 170,
      align: "center",
    },
    {
      id: "credit_limit",
      minWidth: 170,
      align: "center",
    },
    {
      id: "balance",
      minWidth: 170,
      align: "center",
    },
    {
      id: "exposure",
      minWidth: 170,
      align: "center",
    },
    {
      id: "winnings",
      minWidth: 170,
      align: "center",
    },
    {
      id: "partnership",
      minWidth: 170,
      align: "center",
    },
    {
      id: "credit_ref",
      minWidth: 170,
      align: "center",
    },
    {
      id: "suspended",
      minWidth: 170,
      align: "center",
    },
    {
      id: "bet_suspended",
      minWidth: 170,
      align: "center",
    },
    {
      id: "action",
      minWidth: 170,
      align: "center",
    },
    {
      id: "se",
      minWidth: 170,
      align: "center",
    },
  ];

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();
    const params = useParams();

    let toRender = null;

    switch (action.toRender) {
      case "add":
        toRender = (
          <AddUser
            handleClose={handleClose}
            paper={classes.paper}
            gridy={classes.gridy}
            usertype={parseFloat(params.usertype)}
            uplink={userInfo.username}
            updateRows={updateRows}
            commission={commission}
          />
        );
        break;
      case "addChild":
        toRender = (
          <AddUser
            handleClose={handleClose}
            paper={classes.paper}
            gridy={classes.gridy}
            usertype={parseFloat(params.usertype) + 1}
            uplink={action.username}
            updateRows={updateRows}
            commission={commission}
          />
        );
        break;
      case "deposit":
        toRender = (
          <Deposit
            handleClose={handleClose}
            updateRows={updateRows}
            paper={classes.paper}
            gridy={classes.gridy}
            userBalance={action.balance}
            coins_generated={userInfo.coins_generated}
            uplink={params.usertype === "2" ? "admin" : action.uplink}
            downlink={action.username}
            downlink_type={params.usertype}
            uplink_type={(parseFloat(params.usertype) - 1).toString()}
          />
        );
        break;
      case "withdraw":
        toRender = (
          <Withdraw
            handleClose={handleClose}
            updateRows={updateRows}
            paper={classes.paper}
            gridy={classes.gridy}
            userBalance={action.balance}
            coins_withdrawn={userInfo.coins_withdrawn}
            uplink={params.usertype === "2" ? "admin" : action.uplink}
            downlink={action.username}
            downlink_type={params.usertype}
            uplink_type={(parseFloat(params.usertype) - 1).toString()}
          />
        );
        break;
      case "password":
        toRender = (
          <ChangePassword
            handleClose={handleClose}
            username={action.username}
            paper={classes.paper}
          />
        );
        break;
      case "delete":
        toRender = (
          <Delete
            handleClose={handleClose}
            username={action.username}
            usertype={action.usertype}
            paper={classes.paper}
            updateRows={updateRows}
          />
        );
        break;
      case "viewinfo":
        toRender = (
          <ViewInfo
            handleClose={handleClose}
            username={action.username}
            commission={commission}
            paper={classes.bigpaper}
            updateRows={updateRows}
          />
        );
        break;
      case "settlement":
        toRender = (
          <Settlement
            handleClose={handleClose}
            updateRows={updateRows}
            uplink={params.usertype === "2" ? "admin" : action.uplink}
            downlink={action.username}
            chips={action.winnings}
          />
        );
        break;
      case "fullname":
        toRender = (
          <ChangeName
            username={action.username}
            handleClose={handleClose}
            updateRows={updateRows}
          />
        );
        break;
      case "credit":
        toRender = (
          <Credit
            username={action.username}
            handleClose={handleClose}
            updateRows={updateRows}
          />
        );
        break;
      case "partnership":
        toRender = (
          <Partnership
            username={action.username}
            handleClose={handleClose}
            updateRows={updateRows}
            partnership={action.partnership}
            bookmaker_com={action.bookmaker_com}
            fancy_com={action.fancy_com}
            commission={commission}
          />
        );
        break;
      default:
        break;
    }
    return (
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {users
            .filter((obj) => {
              return obj.usertype === params.usertype;
            })
            .map((obj) => obj.type)}{" "}
          listing
        </Typography>
        {show ? (
          <Button
            className={classes.addUserBtn}
            variant='contained'
            onClick={() => {
              modalChange("add", "");
            }}
          >
            Add User
          </Button>
        ) : (
          <div></div>
        )}
        <div>
          <Modal open={open} bigmodal={bigmodal} onClose={handleClose}>
            {toRender}
          </Modal>
        </div>
      </Toolbar>
    );
  };

  useEffect(() => {
    axios
      .get("/user/info", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res1) => {

        if (res1.data.success) {
          setCommission({
            book_com: res1.data.user.book_com,
            bookmaker_com: res1.data.user.bookmaker_com,
            fancy_com: res1.data.user.fancy_com
          })
          let myUsertype = parseFloat(res1.data.user.usertype);
          let usertype = parseFloat(params.usertype);
          setUserInfo(res1.data.user);
          setUplink(usertype - myUsertype);
          if (usertype - myUsertype === 1) {
            setShowAdd(true);
          } else {
            setShowAdd(false);
          }

          axios
            .get("/user/getUsers/" + usertype, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                setBufferRows(res.data.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [shouldUpdate, params?.usertype]);

  useEffect(() => {

    if (bufferRows && bufferRows.length) {

      const columns = bufferRows[0] && Object.keys(bufferRows[0])
      let newRows = search ? bufferRows.filter(bufferRow =>
        columns.some(
          column => bufferRow[column]?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
        )) : bufferRows

      setrows(newRows)
    } else if (bufferRows !== null && !bufferRows.length) {
      setrows([])
    }

  }, [search, bufferRows])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const updateRows = () => {
    setUpdate((prevValue) => {
      return !prevValue;
    });
  };

  const accountStatement = (username, usertype) => {
    history.push({
      pathname: "/accountStatement",
      search: "?username=" + username + "&usertype=" + usertype,
    });
  };

  const modalChange = (perform, user, balance, uplink, winnings, partnership, bookmaker_com, fancy_com) => {
    if (perform === "viewinfo") {
      setBigmodal(true);
    } else {
      setBigmodal(false);
    }
    const obj = {
      username: user,
      toRender: perform,
      balance: balance,
      uplink: uplink,
      winnings: winnings,
      partnership: partnership,
      bookmaker_com: bookmaker_com,
      fancy_com: fancy_com
    };
    handleOpen();
    setAction(obj);
  };
  if (uplink === 1) {
    delete columns[1];
  }

  const toggleSuspend = (username) => {
    const payload = {
      username: username
    }
    axios.put('/user/toggleSuspend', payload, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        if (response.data.success) {
          updateRows()
          alertify.success(response.data.message);
        }
        else {
          alertify.error(response.data.message);
        }
      })
      .catch(error => {
        alertify.error(error);
      })
  }
  const toggleBetSuspend = (username) => {
    const payload = {
      username: username
    }
    axios.put('/user/toggleBetSuspend', payload, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        if (response.data.success) {
          updateRows()
          alertify.success(response.data.message);
        }
        else {
          alertify.error(response.data.message);
        }
      })
      .catch(error => {
        alertify.error(error);
      })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <HelperLabel />
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleSearch}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows ? rows.length : 0}
              uplink={uplink}
            />
            <TableBody>
              {rows ? !rows.length ? (
                <TableRow>
                  <TableCell>No data</TableCell>
                </TableRow>
              ) : null :
                <TableRow className={classes.loader}>
                  <TableCell colSpan={columns.length}>
                    <BetSpinner/>
                  </TableCell>
                </TableRow>
              }
              {stableSort(rows, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={row.username}>
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id === "action" ? (
                          <TableCell
                            key={column.id}
                            align="center"
                            padding="none"
                            style={{ minWidth: '340px' }}
                          >
                            {row.usertype === "5" ? null : (
                              <button
                                onClick={() => {
                                  modalChange("addChild", row.username);
                                }}
                                className={classes.actionButton}
                              >
                                A
                              </button>
                            )}
                            <button
                              onClick={() => {
                                modalChange("partnership",
                                  row.username,
                                  row.balance,
                                  row.uplink,
                                  row.winnings,
                                  row.partnership,
                                  row.bookmaker_com,
                                  row.fancy_com
                                );
                              }}
                              className={classes.actionButton}
                            >
                              PC
                            </button>
                            <button
                              className={classes.actionButton}
                              onClick={() => {
                                accountStatement(row.username, row.usertype);
                              }}
                            >
                              S
                            </button>
                            <button
                              className={classes.actionButton}
                              onClick={() => { history.push(`/userProfitLoss/${row.username}`) }}
                            >PL</button>
                            {row.usertype === "5" ? (
                              <button
                                className={classes.actionButton}
                                onClick={() => {
                                  modalChange(
                                    "viewinfo",
                                    row.username,
                                    row.balance,
                                    row.winnings,
                                    row.uplink,
                                  );
                                }}
                              >
                                I
                              </button>
                            ) : null}
                            <button
                              className={classes.actionButton}
                              onClick={() => {
                                modalChange(
                                  "password",
                                  row.username,
                                  row.balance,
                                  row.uplink
                                );
                              }}
                            >
                              P
                            </button>
                            <button
                              onClick={() => {
                                modalChange(
                                  "deposit",
                                  row.username,
                                  row.balance,
                                  row.uplink
                                );
                              }}
                              className={classes.actionButton}
                            >
                              D
                            </button>
                            <button
                              onClick={() => {
                                modalChange(
                                  "withdraw",
                                  row.username,
                                  row.balance,
                                  row.uplink
                                );
                              }}
                              className={classes.actionButton}
                            >
                              W
                            </button>
                            <button
                              onClick={() => {
                                modalChange(
                                  "credit",
                                  row.username,
                                  row.balance,
                                  row.uplink
                                );
                              }}
                              className={classes.actionButton}
                            >
                              C
                            </button>
                            <button
                              onClick={() => {
                                modalChange(
                                  "fullname",
                                  row.username,
                                  row.balance,
                                  row.uplink
                                );
                              }}
                              className={classes.actionButton}
                            >
                              F
                            </button>
                            <button
                              onClick={() => {
                                history.push('/userActivity/' + row.username)
                              }}
                              className={classes.actionButton}
                            >
                              L
                            </button>
                            {userInfo.usertype === "1" ? (
                              <button
                                onClick={() => {
                                  modalChange(
                                    "delete",
                                    row.username,
                                    row.balance,
                                    row.uplink
                                  );
                                }}
                                className={classes.actionButton}
                              >
                                Del
                              </button>
                            ) : null}
                          </TableCell>
                        ) : column.id === "se" ? (
                          <TableCell
                            key={column.id}
                            align="center"
                            padding="none"
                          >
                            <button
                              className={classes.actionButton}
                              onClick={() => {
                                modalChange(
                                  "settlement",
                                  row.username,
                                  row.balance,
                                  row.uplink,
                                  row.winnings
                                );
                              }}>SE</button>
                          </TableCell>
                        ) : column.id === "username" && row.usertype !== "5" ? (
                          <TableCell
                            key={column.id}
                            align="center"
                            padding="none"
                          >
                            <Link
                              style={{ cursor: "pointer" }}
                              to={"/childlist/" + row.username}
                            >
                              {row[column.id] + '(' + row.fullname + ')'}
                            </Link>
                          </TableCell>
                        ) : column.id === "credit_limit" ? (
                          <TableCell
                            key={column.id}
                            align="center"
                            padding="none"
                          >
                            {formatCoins(row.balance + row.exposure - row.winnings)}
                          </TableCell>
                        ) : column.id === "exposure" && row[column.id] && row.usertype === "5" ?
                          <TableCell
                            key={column.id}
                            align="center"
                            padding="none"
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                history.push('/expoBets/' + row.username)
                              }}
                            >
                              {formatCoins(row[column.id])}
                            </Button>
                          </TableCell>
                          : column.id === "username" && row.usertype === '5' ?
                            <TableCell
                              key={column.id}
                              align="center"
                              padding="none"
                            >
                              {row[column.id] + '(' + row.fullname + ')'}
                            </TableCell>
                            : column.id === 'bet_suspended' ?
                              <TableCell
                                key={column.id}
                                align="center"
                                padding="none"
                              >
                                <Switch
                                  checked={row.bet_suspended === 1 ? true : false}
                                  onChange={() => { toggleBetSuspend(row.username) }}
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                /></TableCell>
                              : column.id === 'suspended' ?
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  padding="none"
                                >
                                  <Switch
                                    checked={row.suspended === 1 ? true : false}
                                    onChange={() => { toggleSuspend(row.username) }}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  /></TableCell> : (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : formatCoins(value)}
                                  </TableCell>
                                );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}