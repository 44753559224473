import React, { useContext, useEffect, useState } from 'react'
import config from '../config'
import io from 'socket.io-client'

const SocketContext = React.createContext()

export function useSocket() {
    return useContext(SocketContext)
}

export function SocketProvider({ children }) {
    const [socket, setSocket] = useState()

    useEffect(() => {
        const url = config.NODE_ENV==='development' ? config.SOCKET_DEV_URL : config.SOCKET_URL
        const newSocket = io(url, {
            'transports': ['websocket'],
            'allowEIO4': true,
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionDelayMax': 5000,
            'reconnectionAttempts': 5
        })
        setSocket(newSocket)
        return () => newSocket.close()
    }, [])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}