import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BetSpinner from "../UI/Spinner/BetSpinner";

export default function SplashScreen({ children }) {
  const open = useSelector((state) => state.auth.loading);
  const [splashScreen, setSplashScreen] = useState(open);

  useEffect(() => {
    setSplashScreen(open);
  }, [open]);

  return (
    <div>
      {splashScreen ?
        <div
          style={{
            display: "",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            background: 'linear-gradient(180deg, #f5f7fa, #c3cfe2)'
          }}
        >
          <BetSpinner />
        </div>
        : <>{children}</>}
    </div>
  );
};
