import React, { forwardRef, useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper'
import AnalyticsIcon from '@material-ui/icons/BarChartRounded';
import classes from './MarketTable.module.css';
import MarketRow from "./MarketRow";
import { useSelector } from "react-redux";
import { formatCoins, getDeviceType } from "../../../../../../helpers/common";
import { useSocket } from "../../../../../../contexts/SocketProvider";
import BetSpinner from "../../../../../UI/Spinner/BetSpinner";

const MarketTableNew = forwardRef((props,ref) => {

    const [profit, setProfit] = useState(0)
    const [loss, setLoss] = useState(0)
    const [selection, setSelection] = useState(null)
    const [odds, setOdds] = useState(null)
    const usertype = useSelector(state => state.auth.usertype)
    const socket = useSocket()

    useEffect(() => {

        const key = props.name.toLowerCase() === 'bookmaker' ? 'Bookmaker/Auto' : 'Event/Auto'

        socket.on(`${key}/${props.eventId}`, (data) => {
            setOdds(data)
        });

        socket.emit(key, {eventId: props.eventId, marketId: props.oddsMarketId});

        socket.on('disconnect', () => {
            console.log(`Disconnected from server for ${key}`);
        });

        return () => {
            socket.disconnect();
        };

    }, []);

    const setProfitLoss = (stake, odds, selection, isBookmaker) => {
        const cur_odds = isBookmaker ? 1 + parseFloat(odds)/100 : parseFloat(odds)
        if (selection === 'back') {
            setProfit(stake * (cur_odds - 1))
            setLoss(-stake)
            setSelection(selection)
        }
        else if (selection === 'lay') {
            setProfit(stake)
            setLoss(-stake * (cur_odds - 1))
            setSelection(selection)
        }
    }
    const clearProfitLoss = () => {
        setProfit(0)
        setLoss(0)
    }

    let marketRow = <BetSpinner/>

    if(odds){
        marketRow = props.runners.map(runner => {
            // const reqRunner = odds?.[0]?.runners?.filter(odds_runner => odds_runner.nation === runner.name)[0]

            // if(reqRunner && props.name.toLowerCase() === 'bookmaker'){
            //     const backOdds = reqRunner.back.filter(back=>back.price!=="0")
            //     if(backOdds.length && parseFloat(backOdds[0].price)>100 )
            //         reqRunner.status= 'SUSPENDED'
            // }


            const reqRunner = odds?.[0]?.runners?.filter(odds_runner =>{
                if(props.name.toLowerCase() === 'bookmaker')
                    return odds_runner.RunnerName === runner.name
                else
                    return odds_runner.selectionId === runner.selectionId

            } )[0]

            if(reqRunner){
                reqRunner["back"] = reqRunner?.ex?.availableToBack
                reqRunner["lay"] = reqRunner?.ex?.availableToLay
            }

            if(reqRunner && props.name.toLowerCase() === 'bookmaker'){
                const backOdds = reqRunner.back.filter(back=>back.price!=="0")
                if(backOdds.length && parseFloat(backOdds[0].price)>100 )
                    reqRunner.status= 'SUSPENDED'
            }
            return <MarketRow
                name={runner.name}
                selectionId={runner.selectionId}
                runnerProfitLoss={(usertype === '1' ? runner.net_profit_wc : runner.net_profit) ?? 0}
                key={runner.selectionId + props.marketId}
                marketId={props.marketId}
                marketName={props.name}
                adv_max={props.adv_max}
                min={props.min}
                max={props.max}
                eventId={props.eventId}
                showRunnerPL={props.showRunnerPL}
                sport={props.sport}
                eventName={props.eventName}
                shift={reqRunner ? 3 - reqRunner.back?.length : 0}
                marketStatus={reqRunner ? odds?.[0].status : 'SUSPENDED'}
                inplay={reqRunner ? odds[0]?.inplay : false}
                status={reqRunner ? reqRunner.status : 'SUSPENDED'}
                availableToBack={reqRunner ? reqRunner.back : []}
                availableToLay={reqRunner ? reqRunner.lay : []}
                setProfitLoss={setProfitLoss}
                profitLoss={runner.selectionId + props.marketId === props.activeId ?
                    (selection && selection === 'lay' ? loss : profit) :
                    (selection && selection === 'lay' ? profit : loss)}
                clearProfitLoss={clearProfitLoss}
                isActive={runner.selectionId + props.marketId === props.activeId}
                handleClick={props.handleClick}
                accept={props.accept}
                stakes={props.stakes}
                toggleAcceptAnyOdds={props.toggleAcceptAnyOdds}
                updateBook={props.updateBook}
            />
        })
    }

    return (
        <Paper style={{marginBottom: '15px'}}>
            <div className={classes.headGrid}>
                <div className={classes.box}>
                    <div className={classes.tableName}>{props.name}</div>
                    {usertype === '5' ? null : <AnalyticsIcon
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            const deviceType = getDeviceType()
                            if (deviceType === 'mobile')
                                ref.current.scrollIntoView({ behavior: 'smooth' });
                            props.changeFlag()
                            props.changeMarket(props.marketId, props.eventId, props.name)
                        }}
                    />}
                </div>
                <div className={classes.tableMaxMin}
                    onClick={() => {
                        if (usertype === '1')
                            props.showMaxUpdate(props.marketId)
                    }}
                >
                    Min/Max :- <span>{`${formatCoins(props.min)}/${odds?.[0]?.inplay ? formatCoins(props.max) : formatCoins(props.adv_max)}`}</span>
                </div>
            </div>
            <div className={classes.upperGrid}>
                <div className={classes.textCenter}></div>
                <div className={[classes.textCenter, classes.nameBlue].join(' ')}>Back</div>
                <div className={[classes.textCenter, classes.namePink].join(' ')}>Lay</div>
                <div className={[classes.textCenter, classes.mobile].join(' ')}></div>
            </div>
            {marketRow}
        </Paper>
    );
})

export default MarketTableNew
