import React, { useState,useEffect } from 'react'
import DateTime from '../../../UI/DateTime/DateTime'
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import axios from '../../../../axios-instance/backendAPI'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    InputBase,
} from "@material-ui/core"
import { useSelector } from 'react-redux'
import { formatCoins } from '../../../../helpers/common'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

    let headCells = [
        {
            id: "client",
            numeric: true,
            disablePadding: false,
            label: "Username",
        },
        {
            id: "Cricket",
            numeric: true,
            disablePadding: false,
            label: "Cricket",
        },
        {
            id: "Soccer",
            numeric: true,
            disablePadding: false,
            label: "Soccer",
        },
        {
            id: "Tennis",
            numeric: true,
            disablePadding: false,
            label: "Tennis",
        },
        {
            id: "fancy",
            numeric: true,
            disablePadding: false,
            label: "Fancy",
        },
        {
            id: "casino",
            numeric: true,
            disablePadding: false,
            label: "Casino",
        }
    ];

    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.heading}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 10px 10px 10px'
    },
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        overflow: "scroll",
        '& .MuiTableCell-root': {
            fontSize: 'var(--large_font)', // Adjust the font size as needed
        },
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
            '& .MuiTableCell-root': {
                fontSize: 'var(--small_font)', // Adjust the font size as needed
            },
        },
    },
    pagination: {
        '& .MuiTablePagination-caption': {
            fontSize: 'var(--large_font)', // Font size for caption
        },
        '& .MuiTablePagination-input': {
            fontSize: 'var(--large_font)', // Font size for input
        },
        [theme.breakpoints.down("sm")]: {
            '& .MuiTablePagination-caption': {
                fontSize: 'var(--small_font)', // Font size for caption
            },
            '& .MuiTablePagination-input': {
                fontSize: 'var(--small_font)', // Font size for input
            }, 
        }
    },
    cell: {
        minWidth: '300px'
    },
    smallCell: {
        minWidth: '150px'
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    heading: {
        padding: "2px",
        fontWeight: "bold",
        fontSize: "0.8em",
        backgroundColor: "#e7e7e7",
        border: "1px solid #8a8a82",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid black",
        float: "right",
        marginBottom: "5px",
        width: "18%",
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(3),
            width: "30%",
            height: "30px",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            '& svg': {
                fontSize: 'var(--very_large_font)', // Font size for input
            },
        }
    },
    actionButton: {
        margin: 3,
        cursor: "pointer",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        fontSize: 'var(--large_font)',
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: `calc(${theme.spacing(4)}px)`,
            fontSize: 'var(--small_font)',
        },
    },
}));

export default function UserPL() {

    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("website");
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [bufferRows, setBufferRows] = useState([])
    const [search, setSearch] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const token = useSelector(state => state.auth.token)

    const onSubmit = (from, to, value) => {

        axios.get('/user/getUserPL/' + from + '/' + to, {
            headers: { Authorization: `Bearer ${token}`},
        })
            .then(response => {

                if (response.data.success) {
                    setBufferRows(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {

        const columns = bufferRows[0] && Object.keys(bufferRows[0])
        let newRows = search ? bufferRows.filter(bufferRow =>
            columns.some(
                column => bufferRow[column]?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
            )) : bufferRows

        setRows(newRows)

    }, [search, bufferRows])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const columns = [
        {
            id: "client",
            align: "center",
            minWidth: 600
        },
        {
            id: "Cricket",
            align: "center"
        },
        {
            id: "Soccer",
            align: "center"
        },
        {
            id: "Tennis",
            align: "center"
        },
        {
            id: "fancy",
            align: "center"
        },
        {
            id: "casino",
            align: "center"
        }
    ]

    let table = <>
        <DateTime
            filter={[]}
            onSubmit={onSubmit}
            name='User PL Listing'
            search={search}
        />
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>

                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={handleSearch}
                        inputProps={{ "aria-label": "search" }}
                    />
                </div>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {!rows.length ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length}>No data</TableCell>
                                </TableRow>
                            ) : null}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.client}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className={column.id === 'client' ? classes.cell : classes.smallCell}
                                                    >
                                                        {column.id === 'client' ?
                                                            value + '(SEN:' + row.seniorsuper + ',SUP:' + row.supermaster + ',MAS:' + row.master + ')'
                                                            : formatCoins(value)}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.pagination}
                />
            </Paper>
        </div>
    </>

    return (
        <div className={classes.container}>
            {table}
        </div>
    )
}