import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "../../../axios-instance/backendAPI";
import { useSelector } from "react-redux";
import { sports } from "../../../helpers/constant";
import { getDeviceType, redirectToFullMarket } from "../../../helpers/common";

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [sportList, setSportList] = useState([]);
  const token = useSelector(state => state.auth.token)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const devicetype = getDeviceType()

    if(devicetype!='mobile')
      axios
        .get("/user/getAllMatches", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.success) {
            setSportList(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.tab}>Sports</div>
      {sports.map((sport) => {
        return (
          <Accordion
            key={sport.id}
            expanded={expanded === sport.name}
            onChange={handleChange(sport.name)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>{sport.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List component="nav">
                {sportList
                  .filter((data) => data.sport === sport.id)
                  .map((data) => {
                    return (
                      <ListItem
                        button
                        key={data.matchId}
                        className={classes.item}
                        onClick={() => {redirectToFullMarket(data.matchId)}}
                      >
                        <ListItemText
                          primary={data.matchName}
                          className={classes.listText}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "98%",
    margin: '0 auto',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  heading: {
    fontSize: 'var(--large_font)',
    flexBasis: "33.33%",
    flexShrink: 0,
    textAlign: "center",
    color: "var(--header_color)",
    fontWeight: "bolder",
  },
  inplay: {
    color: "var(--header_color)",
    fontWeight: "bolder",
    padding: "16px",
    cursor: "pointer",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  tab: {
    background: "var(--secondary)",
    fontSize: 'var(--large_font)',
    height: "30px",
    color: "var(--header_color)",
    fontWeight:'700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    minWidth: "32px",
  },
  item: {
    padding: "0",
    margin: "0",
    fontSize: "var(--medium_font)",
    color: 'blue'
  },
  listText: {
    "& span, & svg": {
      fontSize: "0.85rem",
      display: "flex",
      alignItems: "start",
    },
  },
}));
