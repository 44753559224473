import BetSlip from "../User/RightSection/Fullmarket/BetSlip/BetSlip";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));

export default function AttachBetSlip(props) {

  const classes = useStyles()

  return <div className={classes.root}>
    <div>{props.children}</div>
    <div><BetSlip display='none'/></div>
  </div>
}