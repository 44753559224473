import React, { useState, useEffect, useRef } from 'react'
import classes from './FancyTable.module.css'
import back_axios from '../../../../../../axios-instance/backendAPI'
import FancyRow from './FancyRow'
import { useSelector } from "react-redux";
import { Paper } from '@material-ui/core'
import { useSocket } from '../../../../../../contexts/SocketProvider'
import { formatCoins } from '../../../../../../helpers/common';

const Fancy = (props) => {

    const [sessions, setSessions] = useState(null)
    const [fancySettings, setFancySettings] = useState({
        max: 0,
        min: 0,
        status: 'on'
    })
    const ref = useRef()
    const token = useSelector(state => state.auth.token)
    const socket = useSocket()

    const updateSessions = (prevSession, updatedSession) => {
        const oldSessions = [...prevSession]
        updatedSession.forEach(session=>{
            const index = oldSessions.findIndex(old_session=>old_session.SelectionId===session.SelectionId)
            session.exposure = index === -1 ? 0 : oldSessions[index].exposure
        })
        return updatedSession
    }

    useEffect(() => {

        const key = 'Fancy/Auto';

        socket.on(`${key}/${props.matchId}`, (data) => {
            if (!ref.current && data) {
                setInitialSessions(data);
                ref.current = true;
            }

            if(Array.isArray(data) && data.length)
                setSessions((prevSession) => {
                    if (prevSession) return updateSessions(prevSession, data);
                    return prevSession;
                });
        });

        // Send a message to the server

        socket.emit(key, props.matchId);

        // Cleanup on component unmount or when matchId changes
        return () => {
            socket.disconnect();
        };
    }, []);

    const setInitialSessions = async(data) => {

        try {
            const payload = {
                event_id: props.matchId,
                sessions: data
            }

            const sessionsData = await back_axios.post('/user/getFancyBookAndSettings', payload , { headers: { Authorization: `Bearer ${token}` } })
            if(sessionsData.data.success)
                setSessions(sessionsData.data.data.sessions)

            setFancySettings(sessionsData.data.data.session_restrictions)
        }catch(err){
            console.log(err)
        }

    }

    let fancyHead = null, fancyRow = null;
    if (Array.isArray(sessions) && sessions.length) {
        fancyHead = (
            <>
                <div className={classes.headGrid}>
                    <div className={classes.tableName}>Fancy Bet</div>
                    <div className={classes.tableName}>  Min/Max :- <span>{formatCoins(fancySettings.min) + '/' + formatCoins(fancySettings.max)}</span></div>
                </div>

                <div className={classes.upperGrid}>
                    <div></div>
                    <div className={classes.odds}>
                        <div className={[classes.textCenter, classes.no].join(' ')}>No</div>
                        <div className={[classes.textCenter, classes.yes].join(' ')}>Yes</div>
                        <div className={[classes.textCenter, classes.mobile].join(' ')}></div>
                    </div>
                </div>
            </>);

        fancyRow = sessions.map(session => {
            return <FancyRow
                key={session.SelectionId}
                id={session.SelectionId}
                name={session.RunnerName}
                layPrice1={session.LayPrice1}
                laySize1={session.LaySize1}
                backPrice1={session.BackPrice1}
                backSize1={session.BackSize1}
                exposure={session.exposure}
                showBook={session.showBook}
                isActive={session.SelectionId === props.activeId}
                handleClick={props.handleClick}
                showModal={props.showModal}
                GameStatus={session.GameStatus}
                max={fancySettings.max}
                min={fancySettings.min}
                eventId={props.matchId}
                sport={props.sport}
                eventName={props.eventName}
                stakes={props.stakes}
            />
        })
    }

    return (
        <Paper className={classes.Fancy}>
            {fancyHead}
            {fancyRow}
        </Paper>
    )
}

export default Fancy