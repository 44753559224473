import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserRouter from "./components/User/User";
import SplashScreen from './components/Hoc/SplashScreen'
import { makeStyles } from '@material-ui/core/styles';
import * as actions from './store/actions/index';
import LeftSection from "./components/User/LeftSection/LeftSection";
import Navigation from "./components/User/Navigation/Navigation";
import { SocketProvider } from './contexts/SocketProvider';
import { localStorageGetItem } from "./helpers/common";
import Footer from "./components/UI/Footer/Footer";
const Login = lazy(() => import("./components/Login/login"));
const SuperadminLogin = lazy(() => import('./components/Login/SuperadminLogin'));
const Superadmin = lazy(() => import('./components/Superadmin/Superadmin'));

const useStyles = makeStyles((theme) => ({
  contab: {
    width: '98%',
    margin: '0 auto',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '15% 85%',
    position: 'relative',
    top: '8em',
    overflow: 'hidden',
    marginBottom: '170px',
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: '100%',
      top: '7.2em'
    },
    [theme.breakpoints.down("xs")]: {
      top: '5.3em'
    }
  }
}))
const App = () => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath)
  const [isSuperadminRoute, setIsSuperadminRoute] = useState(false)
  const [isSuperadminLogin, setIsSuperadminLogin] = useState(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

  useEffect(() => {
    if(window && window.location.pathname.startsWith('/superadmin')) setIsSuperadminRoute(true)

    !isUserLoggedIn && setIsUserLoggedIn(token !== null)

    if(!isSuperadminLogin){
      const superadmin_token = localStorage.getItem('a_token')
      superadmin_token && setIsSuperadminLogin(true)
    }

   }, [token])

  useEffect(() => {
    dispatch(actions.authCheckState())
  }, [])

  let secured = <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/superadmin/login" component={SuperadminLogin} />
      <Route path="/superadmin" component={Superadmin} />
      {localStorageGetItem('token') ? null : <Redirect to={authRedirectPath} />}
    </Switch>
  </Suspense>

  if (isUserLoggedIn)
    secured = <UserRouter />

  const UserPage = () => {
    return <main>
      <Navigation />
      <div className={classes.root}>
        <LeftSection />
        <div className={classes.contab}>
          {secured}
        </div>
      </div>
      <Footer/>
    </main>
  }

  return (<SocketProvider>
    <SplashScreen>
      <BrowserRouter>
        {isSuperadminRoute && isSuperadminLogin ?
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/superadmin/login" component={SuperadminLogin} />
            <Route path="/superadmin" component={Superadmin} />
          </Switch>
        </Suspense> :
        isUserLoggedIn === true ? <UserPage/> : secured}
      </BrowserRouter>
    </SplashScreen>
  </SocketProvider>);
};

export default App;
