import React, { useRef } from "react";
import classes from "./ChangePassword.module.css";
import { useForm } from "react-hook-form";
import axios from "../../../../../../axios-instance/backendAPI";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useSelector } from "react-redux";

export default function ChangePassword(props) {
  const { register, handleSubmit, errors, watch } = useForm();
  const token = useSelector(state => state.auth.token)
  const password = useRef({});
  password.current = watch("newpassword", "");
  const onSubmit = (data) => {
    const payload = {
      username: props.username,
      password: data.password,
      new_password: data.newpassword,
    };
    axios
      .put("/user/resetPassword", payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.success) {
          alertify.success(response.data.message);
          props.handleClose();
        }
        else {
          alertify.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <h3>Change Password for {props.username}</h3>
      <hr />
      <div className={classes.changepass}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.table}>
            <div className={classes.label}>
              <label>New Password* </label>
            </div>
            <input
              name="newpassword"
              type="password"
              className={classes.materialUIInput}
              ref={register({
                required: "You must specify a password",
                minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
                },
                pattern: {
                  value: /.*[!@#$%^&*(),.?":{}|<>].*[!@#$%^&*(),.?":{}|<>].*/,
                  message: "Password must have at least 2 special characters",
                }
              })}
              autoComplete="off"
              autoFocus
            />
            <br />
          </div>
          {errors.newpassword && (
            <p className={classes.p}>{errors.newpassword.message}</p>
          )}
          <div className={classes.table}>
            <div className={classes.label}>
              <label>Confirm Password* </label>
            </div>
            <input
              name="confirmpassword"
              type="password"
              className={classes.materialUIInput}
              ref={register({
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
              autoComplete="off"
            />
            <br />
          </div>
          {errors.confirmpassword && (
            <p className={classes.p}>{errors.confirmpassword.message}</p>
          )}
          <div className={classes.table}>
            <div className={classes.label}>
              <label>Password* </label>
            </div>
            <input
              name="password"
              type="password"
              className={classes.materialUIInput}
              ref={register({
                required: "You must specify a password",
              })}
              autoComplete="off"
            />
            <br />
          </div>
          {errors.password && (
            <p className={classes.p}>{errors.password.message}</p>
          )}
          <div className={classes.button}>
            <input className={classes.input} type="submit" />
          </div>
        </form>
      </div>
    </>
  );
}
