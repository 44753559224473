import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    balance: 0,
    exposure: 0,
    currentBets: null,
    isBetting: false,
}

const updateBalanceOrExposure = (state, action) => {
    return updateObject(state, { balance: action.balance, exposure: action.exposure });
};

const updateCurrentBets = (state, action) => {
    return updateObject(state, { currentBets: action.bet });
};

const setBettingStatus = (state, action) => {
    return updateObject(state, { isBetting: true });
};

const resetBettingStatus = (state, action) => {
    return updateObject(state, { isBetting: false });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_BALANCE: return updateBalanceOrExposure(state, action);
        case actionTypes.UPDATE_CURRENT_BETS: return updateCurrentBets(state, action);
        case actionTypes.SET_BETTING_STATUS: return setBettingStatus(state, action);
        case actionTypes.RESET_BETTING_STATUS: return resetBettingStatus(state, action);
        default:
            return state;
    }
}

export default reducer;